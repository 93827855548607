import React from 'react';
import { useLocaleSelector } from 'gt-next/client';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from '@/components/ui/select';
import { getLocaleProperties } from 'generaltranslation';
import { cn } from '@/lib/utils';

function capitalizeLanguageName(language: string): string {
  if (!language) return '';
  return (
    language.charAt(0).toUpperCase() +
    (language.length > 1 ? language.slice(1) : '')
  );
}

function LocaleDropdown({ className }: { className?: string }) {
  // Retrieve the locale, locales, and setLocale function
  const { locale, locales, setLocale } = useLocaleSelector();

  // Get display name
  const getDisplayName = (locale: string) => {
    return capitalizeLanguageName(
      getLocaleProperties(locale).nativeNameWithRegionCode
    );
  };

  // If no locales are returned, just render nothing or handle gracefully
  if (!locales || locales.length === 0 || !setLocale) {
    return null;
  }

  return (
    <Select onValueChange={setLocale} defaultValue={locale}>
      <SelectTrigger className={cn('rounded-full', className)}>
        <SelectValue placeholder="Select a fruit" />
      </SelectTrigger>
      <SelectContent className="z-[101!important]">
        <SelectGroup>
          {!locale && <SelectItem value="" />}

          {locales.map((locale) => (
            <SelectItem key={locale} value={locale} suppressHydrationWarning>
              {getDisplayName(locale)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default LocaleDropdown;
