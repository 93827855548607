import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/opt/render/project/src/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/(landing)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/packages/services/landing/node_modules/gt-next/dist/provider/ClientProviderWrapper.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/packages/services/landing/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/landing/src/components/frame/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/landing/src/components/frame/Navigation.tsx");
