'use client';

import { cva } from 'class-variance-authority';
import { Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';
import { useEffect, useState, type ButtonHTMLAttributes } from 'react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

// const buttonVariants = cva('size-6 rounded-full p-1 text-fd-muted-foreground', {
//   variants: {
//     dark: {
//       true: 'dark:bg-fd-accent dark:text-fd-accent-foreground',
//       false:
//         'bg-fd-accent text-fd-accent-foreground dark:bg-transparent dark:text-fd-muted-foreground',
//     },
//   },
// });

export default function ThemeToggle({
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>): React.ReactElement {
  const { setTheme, resolvedTheme } = useTheme();

  const onToggle = (): void => {
    setTheme(resolvedTheme === 'dark' ? 'light' : 'dark');
  };

  const [hasMounted, setHasMounted] = useState(false);
  
  useEffect(() => {
      setHasMounted(true);
  }, []);

  return (
    <Button
      variant="outline"
      size="sm"
      className={cn(
        'relative rounded-full w-[56px] h-7 p-0.5',
        'transition-colors duration-300',
        className
      )}
      data-theme-toggle=""
      aria-label="Toggle Theme"
      onClick={onToggle}
      {...props}
    >
      <div
        className={cn(
          'absolute right-[29px] w-7 h-7 rounded-full bg-white border border-slate-200 transition-transform duration-300 flex items-center justify-center',
          resolvedTheme === 'dark' ? 'translate-x-[30px]' : 'translate-x-0'
        )}
      >
        {resolvedTheme === 'dark' && hasMounted ? (
          <Moon className="h-4 w-4 text-slate-900" />
        ) : (
          <Sun className="h-4 w-4 text-slate-900" />
        )}
      </div>
    </Button>
  );
}
