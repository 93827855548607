'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useTheme } from 'next-themes';
import { useEffect, useState, memo, useMemo } from 'react';

import React from 'react';

const Logo = () => {
  const logoLinkClassName =
    'flex items-center gap-2 text-lg font-semibold h-full w-full';

  const { resolvedTheme } = useTheme();

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, [resolvedTheme]);

  return (
    <div className="min-w-[50px]">
      <Link className={logoLinkClassName} href="/">
        <Image
          src={
            hasMounted && resolvedTheme === 'dark'
              ? '/no-bg-gt-logo-dark.png'
              : '/no-bg-gt-logo-light.png'
          }
          width={50}
          height={50}
          alt="GT, Inc."
          priority
        />
        <span className="sr-only">General Translation, Inc.</span>
      </Link>
    </div>
  );
};

export default Logo;
